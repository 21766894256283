<template>
    <button
        v-if="authenticated && !isMaterialTester"
        class="d-inline primary-button"
        @click="navigateToBlogCreate"
    >
        Create a blog <i class="fa fa-plus" />
    </button>
    <div v-else-if="!authenticated" class="my-3 text-secondary">
        Login to create your own blogs.
    </div>
</template>

<script>
import request from "../../apis/request"
import { mapState, mapGetters } from "vuex"

export default {
    mounted() {},
    methods: {
        async navigateToBlogCreate() {
            try {
                const res = await request.get("/blog/check-allowed")
                console.log(res)
            } catch (err) {
                const message =
                    err && err.response && err.response.data && err.response.data.message
                return this.$toasted.error(message || "Cannot create blog.")
            }
            this.$router.push({ name: "blog_create" })
        },
    },
    computed: {
        ...mapState("auth", {
            authenticated: "authenticated",
        }),
        ...mapGetters("auth", {
            isMaterialTester: "isMaterialTester",
        }),
    },
}
</script>

<style></style>
