<template>
    <div class="container m-5">
        <Loader :isLoading="isLoading" />
        <h4 v-if="!myBlogs">Blog Collection</h4>
        <h4 v-else>My Blogs</h4>
        <CreateBlogBtn />
        <div v-if="!isLoading && blogs.length === 0">No blogs posted.</div>
        <div v-if="totalPages > 1" class="text-secondary">Page {{ page }} of {{ totalPages }}</div>
        <div class="text-secondary" v-if="totalResults">Total {{ totalResults }} Blogs</div>

        <div class="row">
            <div class="col-md-4" v-for="blog in blogs" :key="blog.id">
                <BlogListItem :blog="blog" />
            </div>
        </div>

        <Paginator
            :page="page"
            :itemsPerPage="itemsPerPage"
            :totalResults="totalResults"
            @update="pageUpdate"
        />
    </div>
</template>

<script>
import CreateBlogBtn from "./CreateBlogBtn"
import BlogListItem from "./BlogListItem"
import Paginator from "../utils/Paginator.vue"
import request from "../../apis/request"
import scrollTop from "../utils/scrollTop"
import Loader from "../Loader"

export default {
    data() {
        return {
            blogs: [],
            totalResults: 0,
            itemsPerPage: 3,
            page: 1,
            isLoading: false,
            myBlogs: false,
        }
    },
    components: { Loader, CreateBlogBtn, Paginator, BlogListItem },
    mounted() {
        scrollTop()
        if (this.$route.query.myBlogs === "true") {
            this.myBlogs = true
        }
    },
    computed: {
        totalPages() {
            return Math.ceil(this.totalResults / this.itemsPerPage)
        },
    },
    methods: {
        async fetchBlogs() {
            this.isLoading = true
            try {
                const res = await request.get(
                    `/blog?page=${this.page}&limit=${this.itemsPerPage}&myBlogs=${this.myBlogs}`
                )
                const data = res.data
                this.blogs = data.result
                this.totalResults = data.total_results
            } catch (err) {
                console.log("error in loading blogs")
            }
            this.isLoading = false
        },
        async pageUpdate(pageData) {
            const { page, totalPages } = pageData
            if (page) {
                this.page = page
                await this.fetchBlogs()
            }
        },
    },
}
</script>

<style></style>
