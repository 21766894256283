<template>
    <div class="card py-1 px-3 pt-3 my-3 blog-list-item" v-if="blog" @click="openBlog()">
        <h5>{{ blog.title }}</h5>
        <hr />
        <p v-html="shortText(blog.description)" />
    </div>
</template>

<script>
export default {
    props: {
        blog: {
            type: Object,
            default: () => null,
        },
    },
    methods: {
        shortText(text) {
            return text.length > 50 ? text.slice(0, 50) + "..." : text
        },
        openBlog() {
            this.$router.push({ name: "blog", params: { id: this.blog.id } })
        },
    },
}
</script>

<style lang="scss" scoped>
.blog-list-item {
    cursor: pointer;
    background: hsl(0, 0, 90%);
    border: 1px solid transparent;

    &:hover {
        // border: 1px solid #aaa;
        box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.6);
        background: hsl(0, 0, 98%);
    }
}
</style>
